var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      ref: "drawer",
      style: { top: _vm.$vuetify.application.top + "px", zIndex: 6 },
      attrs: { "hide-overlay": "", temporary: true, app: "", clipped: "" },
      on: { transitionend: _vm.drawerChanged },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "pa-2", on: { click: _vm.logout } },
                [
                  _vm.navDrawer
                    ? _c("v-btn", { attrs: { block: "" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("global.menu.account.logout")) +
                            " "
                        ),
                      ])
                    : _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [_c("v-icon", [_vm._v("mdi-logout")])],
                        1
                      ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _vm.authenticated
        ? _c(
            "v-list",
            { attrs: { nav: "" } },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "primary" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: "/ptv-export" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-tag-multiple")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("global.menu.orders"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.hasDriverAuthority
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/customer" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-account-group")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("global.menu.customerManagement")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/order-calendar" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-calendar")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("global.menu.calender"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.hasDriverAuthority && !_vm.redButtonActive
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/billings-journal" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-notebook")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Rechnungsjournal"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAdminAuthority && !_vm.redButtonActive
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/deleted-orders" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-delete-variant")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Gelöscht")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/account" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-account")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("global.menu.account.main"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasAdminAuthority && !_vm.redButtonActive
                    ? _c(
                        "v-card",
                        { attrs: { flat: "", color: _vm.dataGroupCardColor } },
                        [
                          _c(
                            "v-list-group",
                            {
                              attrs: { "prepend-icon": "mdi-database" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "global.menu.entities.main"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3152434715
                              ),
                              model: {
                                value: _vm.dataGroup,
                                callback: function ($$v) {
                                  _vm.dataGroup = $$v
                                },
                                expression: "dataGroup",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/provider" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-hydraulic-oil-level"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "global.menu.entities.provider"
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/driver" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-card-account-details"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "global.menu.entities.driver"
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/product" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-oil")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "global.menu.entities.product"
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/extra-costs" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-cash-multiple"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "global.menu.entities.extraCosts"
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/co2-parameter" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-shape-circle-plus"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("CO2KostAufG"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAdminAuthority && !_vm.redButtonActive
                    ? _c(
                        "v-card",
                        { attrs: { flat: "", color: _vm.adminGroupCardColor } },
                        [
                          _c(
                            "v-list-group",
                            {
                              attrs: { "prepend-icon": "mdi-account-cog" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("global.menu.admin.main")
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1147334409
                              ),
                              model: {
                                value: _vm.adminGroup,
                                callback: function ($$v) {
                                  _vm.adminGroup = $$v
                                },
                                expression: "adminGroup",
                              },
                            },
                            [
                              _vm._v(" "),
                              _vm.hasManagerAuthority
                                ? _c(
                                    "v-list-item-group",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            to: "/admin/user-management",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-account-multiple"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "global.menu.admin.userManagement"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/admin/metrics" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-speedometer"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("global.menu.admin.metrics")
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/admin/health" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-heart")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("global.menu.admin.health")
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/admin/configuration" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-cogs")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "global.menu.admin.configuration"
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/admin/logs" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-post")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("global.menu.admin.logs")
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasManagerAuthority && !_vm.redButtonActive
                    ? _c(
                        "v-card",
                        {
                          attrs: { flat: "", color: _vm.managerGroupCardColor },
                        },
                        [
                          _c(
                            "v-list-group",
                            {
                              attrs: { "prepend-icon": "mdi-account-tie" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        _c("v-list-item-title", [
                                          _vm._v(" Management "),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3016411604
                              ),
                              model: {
                                value: _vm.managerGroup,
                                callback: function ($$v) {
                                  _vm.managerGroup = $$v
                                },
                                expression: "managerGroup",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/management/activities" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-account-clock"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(" Aktivitäten "),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }