import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// prettier-ignore
const ExtraCosts = () => import('@/extra-costs/extra-costs.vue');
// prettier-ignore
const Provider = () => import('@/provider/provider.vue');
// prettier-ignore
const Driver = () => import('@/driver/driver.vue');
// prettier-ignore
const Product = () => import('@/product/product.vue');
// prettier-ignore
const Truck = () => import('@/truck/truck.vue');
// prettier-ignore
const Depot = () => import('@/depot/depot.vue');
// prettier-ignore
const Vendor = () => import('@/vendor/vendor.vue');
// prettier-ignore
const CancellationReason = () => import('@/cancellation-reason/cancellation-reason.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here
const Co2Parameter = () => import('@/co2-parameter/co2-parameter.vue');

export default [
  {
    path: '/extra-costs',
    name: 'ExtraCosts',
    component: ExtraCosts,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/provider',
    name: 'Provider',
    component: Provider,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/driver',
    name: 'Driver',
    component: Driver,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/co2-parameter',
    name: 'Co2Parameter',
    component: Co2Parameter,
    meta: { authorities: [Authority.ADMIN] },
  },
  /*
  {
    path: '/truck',
    name: 'Truck',
    component: Truck,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/depot',
    name: 'Depot',
    component: Depot,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/vendor',
    name: 'Vendor',
    component: Vendor,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/cancellation-reason',
    name: 'CancellationReason',
    component: CancellationReason,
    meta: { authorities: [Authority.ADMIN] },
  },
   */
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
];
